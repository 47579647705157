var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "dcRoundList"
  }, [_c("a-card", {
    attrs: {
      bordered: true
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "1px solid #d9d9d9"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: true
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "manuscriptid",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "channel",
      fn: function fn(channel) {
        return _vm._l(_vm.checkChannel(channel), function (item, key) {
          return _c("a-tag", {
            key: key,
            staticStyle: {
              margin: "2px"
            },
            attrs: {
              color: "blue"
            }
          }, [_vm._v(_vm._s(item))]);
        });
      }
    }, {
      key: "invitePerson",
      fn: function fn(invitePerson, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.creatoruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(invitePerson) + "11")])];
      }
    }, {
      key: "username",
      fn: function fn(username, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.taskid, "taskDetails");
            }
          }
        }, [_vm._v(_vm._s(username) + "11")])];
      }
    }, {
      key: "walletUrl",
      fn: function fn(walletUrl, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.advertiseruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(walletUrl) + "11")])];
      }
    }, {
      key: "hoodId",
      fn: function fn(hoodId, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.advertiseruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(hoodId) + "11")])];
      }
    }, {
      key: "transactionsNumber",
      fn: function fn(transactionsNumber, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.advertiseruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(transactionsNumber) + "11")])];
      }
    }, {
      key: "submittime",
      fn: function fn(submittime) {
        return [_c("span", [_vm._v(_vm._s(_vm.fmtTimeVal(submittime)))])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("a-button", {
          staticStyle: {
            "min-width": "auto",
            padding: "0",
            margin: "0"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.gotoDetail(item);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  }, [_c("template", {
    slot: "status"
  }, [_vm._v(" " + _vm._s(_vm.formatTableField(_vm.status)) + " ")])], 2), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };