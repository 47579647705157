var tableColumns = [{
  title: "uid",
  dataIndex: "uid",
  key: "uid",
  width: 140
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: 140,
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "钱包地址",
  dataIndex: "walletUrl",
  key: "walletUrl",
  width: 140,
  scopedSlots: {
    customRender: "walletUrl"
  }
}, {
  title: "邀请人",
  dataIndex: "invitePerson",
  key: "invitePerson",
  width: 140,
  scopedSlots: {
    customRender: "invitePerson"
  }
}, {
  title: "HOOD ID",
  dataIndex: "hoodId",
  key: "hoodId",
  width: 140,
  scopedSlots: {
    customRender: "hoodId"
  }
}, {
  title: "持有HOOD数量",
  dataIndex: "hoodId",
  key: "hoodId",
  width: 140
}, {
  title: "交易笔数",
  dataIndex: "transactionsNumber",
  key: "transactionsNumber",
  width: 140,
  scopedSlots: {
    customRender: "transactionsNumber"
  }
}, {
  title: "邀请人数",
  dataIndex: "Round",
  key: "Round",
  width: 140
}, {
  title: "注册时间",
  dataIndex: "starttime",
  key: "submittime",
  width: 120,
  scopedSlots: {
    customRender: "submittime"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
var inviteColumns = [{
  title: "邀请ID",
  dataIndex: "Round1",
  key: "Round1",
  width: 140
}, {
  title: "被邀请人",
  dataIndex: "Round2",
  key: "Round2",
  width: 140
}, {
  title: "被邀请人钱包地址",
  dataIndex: "Round3",
  key: "Round3",
  width: 140
}, {
  title: "邀请码",
  dataIndex: "Round4",
  key: "Round4",
  width: 140
}, {
  title: "邀请完成时间",
  dataIndex: "starttime",
  key: "submittime",
  width: 120,
  scopedSlots: {
    customRender: "submittime"
  }
}];
var hoodColumns = [{
  title: "uid",
  dataIndex: "Round1",
  key: "Round1",
  width: 140
}, {
  title: "用户昵称",
  dataIndex: "Round2",
  key: "Round2",
  width: 140
}, {
  title: "钱包地址",
  dataIndex: "Round3",
  key: "Round3",
  width: 140
}, {
  title: "邀请人",
  dataIndex: "Round4",
  key: "Round4",
  width: 140
}, {
  title: "HOOD ID",
  dataIndex: "Round5",
  key: "Round5",
  width: 140
}, {
  title: "交易笔数",
  dataIndex: "Round6",
  key: "Round6",
  width: 140
}, {
  title: "邀请人数",
  dataIndex: "Round7",
  key: "Round7",
  width: 140
}, {
  title: "注册时间",
  dataIndex: "starttime8",
  key: "starttime8",
  width: 120,
  scopedSlots: {
    customRender: "submittime"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { tableColumns, inviteColumns, hoodColumns };